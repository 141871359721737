

.featured-bottom {
    position: fixed;
    bottom: 0;
    height: var(--featured-bottom-height);
    display: flex;
    flex-direction: row;
    padding: 0 var(--page-margin-x);
}

.featured-bottom .featured-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.featured-bottom .featured-card {
    flex: 1;
    position: relative;
    height: var(--featured-bottom-height);
}

.featured-card-body {
    padding-top: calc(var(--featured-bottom-height) / 6);
    padding-left: 2rem;
    padding-right: 2rem;
}

.featured-bottom .featured-card:hover:before {
    transform: translateY(-10%);
    clip-path: polygon(0 -10%, 100% -10%, 100% 100%, 0 100%);
    /*transform: translateY(0%);
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 100%);*/
}

.featured-bottom .featured-card:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    z-index: -1;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    clip-path: polygon(0 110%, 100% 110%, 100% 220%, 0 220%);
    transition: transform 0.5s, clip-path 0.5s;
    transform: translateY(-100%);
}

.featured-bottom .featured-card:hover h4 {
    top: -3rem;
    color: white;
    font-size: 1.2rem;
}

.featured-bottom .featured-card h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  position: relative;
  top: 0;
  transition: top 0.5s, font-size 0.5s;
}

.featured-bottom .featured-card:hover p {
    opacity: 0;
}

.featured-bottom .featured-card p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0.5rem 0;
  transition: opacity 0.5s;
  opacity: 1;
}

.featured-bottom .footer {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.featured-bottom .footer-wrapper {
    padding-left: 2rem;
    font-size: 1.2rem;
    width: var(--featured-bottom-height);
    display: inline-flex;
    flex-direction: row;
    transform: rotate(90deg);
    gap: 1rem;
}

.featured-bottom .footer a {
    text-decoration: none;
    color: var(--color-text);
}

.featured-bottom .footer a:visited {
    color: var(--color-text);
}
